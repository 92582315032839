import i18n from 'config/localisation';

enum PlaceOrderErrors {
  CART_EMPTY = '105',
  CART_NOT_CALCULATED = '107',
  EXPIRED_SLOT = '101',
  EXPIRED_SLOT_STANDARD = '101_standard',
  EXPIRED_SLOT_EXPRESS = '101_express',
  EXPIRED_SLOT_QCOMM = '101_qcomm',
  EXPIRED_SLOT_QELEC = '101_qelec',
  EXPIRED_SLOT_CNC = '101_cnc',
  PMV_101 = 'PMV-101',
  PMV_102 = 'PMV-102',
  PMV_103 = 'PMV-103',
}

export default PlaceOrderErrors;

export const alertMessageKey = 'alertMessage';
export const getAlertMessageExpress = () => i18n.t('alertMessageExpress');
