import {
  IOrderConfirmationShipment,
  IOrderConfirmationShipmentProduct,
  IShipmentAndFeeLabels,
  ORDER_CONFIRMATION_DETAILS_KEY,
} from './../OrderConfirmation.types';
import { getFormattedPrice } from 'utils/price.util';
import getFormattedFullAddress from 'pages/Checkout/components/Delivery/helpers/getFormattedFullAddress';
import { ISlotItem } from 'common/DeliverySlot/redux/deliverySlot.type';
import i18n from 'config/localisation';
import { IConfirmationOrderDetailsResponse } from 'pages/OrderDetail/orderData.type';
import { EShipmentTypes, ISellersEntry, IShipment } from 'services/shipments/types/shipments.types';
import getNonFoodDeliveryInfo from 'pages/Checkout/components/Shipments/helpers/getNonFoodDeliveryInfo.helper';
import FEATURES_CONFIG from 'config/features';
import { getPurchasedProductsData } from './purchasedProductData.utils';
import { DeliveryCost, IMarketPlaceDeliveryCost, Product } from 'network/response/ICart';
import { IAppState } from 'config/redux/reducers';
import { IMapString } from 'common/interfaces/common.interface';
import { isNonEmptyObject } from 'utils/helpers.util';

const { offerSellerIds = [] } = FEATURES_CONFIG.settings;

const getTranslatedStringFor = (key: string, isAtRoot = false) =>
  i18n.t(`${isAtRoot ? '' : 'orderConfirmation.'}${key}`);

export const generateDeliveryDateText = ({ dayOfWeek, deliveryDate }: ISlotItem): string =>
  `${dayOfWeek}, ${deliveryDate}`;
export const generateDeliveryTimeText = ({ beginTime, endTime }: ISlotItem): string =>
  `${beginTime} - ${endTime}`;

/**
 *
 * @param shipments - Order detail API response's shipments
 * @param shipmentType - shipment type
 * @returns - Shipment for passed shipment type
 */
const extractShipmentByType = (shipments: IShipment[] = [], shipmentType: string): any => {
  return shipments.find(shipment => shipment.shipmentType === shipmentType) || null;
};

/**
 *
 * @param shipment - Order shipment or seller entry
 * @returns {Object} Required product's data for individual shipment
 */
const getShipmentProductsData = (
  shipment: IShipment | ISellersEntry
): IOrderConfirmationShipmentProduct[] => {
  return shipment.entries.map(
    ({ totalPrice, quantity, bundleId, product = {} as Product, isFreebie }) => {
      const { images = [] } = product;
      const { url: imageURL } = images.find(img => img.format === 'pdpPreview') || { url: '' };

      return {
        productName: product.onlineName || '',
        productType: product.productType || '',
        quantity,
        imageURL,
        totalPrice,
        isFreebie,
        ...(bundleId ? { bundleId } : {}),
      };
    }
  );
};

/**
 *
 * @param shipment
 * @param shipmentType
 * @returns {String} - Delivery description text which need to show for indiviual shipment
 */
const getDeliveryDescription = (shipment: any, shipmentType: string) => {
  const {
    deliveryPromise,
    expressDeliveryMessage,
    entries,
    shopId = '',
    deliverySlot = null,
  } = shipment;

  let deliveryDescription = '';

  if (
    shipmentType === EShipmentTypes.STANDARD_NONFOOD ||
    shipmentType === EShipmentTypes.MARKETPLACE
  ) {
    deliveryDescription =
      expressDeliveryMessage || deliveryPromise || getNonFoodDeliveryInfo(entries);

    if (offerSellerIds.includes(shopId) && shipmentType === EShipmentTypes.MARKETPLACE) {
      deliveryDescription = i18n.t('spc.mkpDate');
    }
  } else if (shipmentType === EShipmentTypes.QCOMM) {
    deliveryDescription =
      deliverySlot && deliverySlot.deliveryMessage
        ? deliverySlot.deliveryMessage
        : getTranslatedStringFor('15mins');
  } else if (shipmentType === EShipmentTypes.QELEC) {
    deliveryDescription =
      deliverySlot && deliverySlot.deliveryMessage
        ? deliverySlot.deliveryMessage
        : getTranslatedStringFor('120mins');
  } else if (shipmentType === EShipmentTypes.EXPRESS) {
    deliveryDescription = deliverySlot
      ? deliverySlot.expressDeliveryMessage
      : getTranslatedStringFor('60mins');
  } else if (deliverySlot) {
    deliveryDescription = `${generateDeliveryDateText(deliverySlot)}, ${generateDeliveryTimeText(
      deliverySlot
    )} `;
  }
  return deliveryDescription;
};

/**
 *
 * @param orderDetailResponse - Order Detail API Response
 * @returns {boolean} - Is order containing any sustituatable product
 */
const isOrderSubstituatable = (orderDetailResponse: IConfirmationOrderDetailsResponse) => {
  const shipment = extractShipmentByType(
    orderDetailResponse.shipments,
    EShipmentTypes.STANDARD_FOOD
  );
  if (!shipment) {
    return false;
  }
  return shipment.entries.some((entry: any) => entry.isSubstituted);
};

/**
 *
 * @description - Provides the all MKP shipment seller name
 * @param orderDetailResponse - Order Detail API Response
 * @returns {Array} - All seller names of market place shipments
 */
const getSellers = (orderDetailResponse: IConfirmationOrderDetailsResponse) => {
  const mkpShipment = extractShipmentByType(
    orderDetailResponse.shipments,
    EShipmentTypes.MARKETPLACE
  );
  const sellers = mkpShipment
    ? mkpShipment.sellersEntries.reduce(
        (acc: any[], entry: ISellersEntry) => [...acc, entry.shopName],
        []
      )
    : [];
  return sellers;
};

/**
 *
 * @param shipments - Order detail API response's shipments
 * @returns {Array} - All shipment types of the order
 */
const getShipmentTypes = (shipments: IShipment[] = []) => {
  const shipmentTypes = shipments.reduce(
    (acc: any[], shipment: IShipment) => [...acc, shipment.shipmentType],
    []
  );
  return shipmentTypes;
};

const getShipmentAndFeeLabels = ({
  shipmentType,
  sellerName = '',
  apigeeLabels = {},
}: {
  shipmentType: string;
  sellerName?: string;
  apigeeLabels?: IMapString;
}): IShipmentAndFeeLabels => {
  if (shipmentType === EShipmentTypes.QCOMM) {
    return {
      shipmentNameLabel: apigeeLabels.shipmentQcomm,
      deliveryFeeLabel: apigeeLabels.qcommDeliveryFees,
      serviceFeeLabel: apigeeLabels.qcommServiceFees,
    };
  } else if (shipmentType === EShipmentTypes.QELEC) {
    return {
      shipmentNameLabel: apigeeLabels.shipmentQelec,
      deliveryFeeLabel: apigeeLabels.qelecDeliveryFees,
      serviceFeeLabel: apigeeLabels.qelecServiceFees,
    };
  } else if (shipmentType === EShipmentTypes.EXPRESS) {
    return {
      shipmentNameLabel: apigeeLabels.shipmentNow,
      deliveryFeeLabel: apigeeLabels.nowDeliveryFees,
      serviceFeeLabel: apigeeLabels.nowServiceFees,
    };
  } else if (shipmentType === EShipmentTypes.CNC) {
    return {
      shipmentNameLabel: apigeeLabels.shipmentCnc,
    };
  } else if (shipmentType === EShipmentTypes.STANDARD_NONFOOD) {
    return {
      shipmentNameLabel: apigeeLabels.shipmentNonFood,
      deliveryFeeLabel: apigeeLabels.nonFoodDeliveryFees,
      serviceFeeLabel:
        apigeeLabels.nonFoodServiceFees || i18n.t('order_details.shipments.nonFoodServiceFees'),
    };
  } else if (shipmentType === EShipmentTypes.MARKETPLACE) {
    return {
      shipmentNameLabel: sellerName,
      deliveryFeeLabel:
        apigeeLabels.mkpDeliveryFees && apigeeLabels.mkpDeliveryFees.replace(/%@/g, sellerName),
    };
  }

  return {
    shipmentNameLabel: apigeeLabels.shipmentFood,
    deliveryFeeLabel: apigeeLabels.scheduledDeliveryFees,
    serviceFeeLabel: apigeeLabels.scheduledServiceFees,
    surchargeFeeLabel: apigeeLabels.scheduledSlotFees,
  };
};

/**
 *
 * @description - Prepares order meta data
 * @param orderDetailResponse - Order Detail API Response
 * @returns
 */
const prepareOrderMeta = (orderDetailResponse: IConfirmationOrderDetailsResponse) => {
  const {
    totalPrice,
    code: orderNumber,
    totalItems,
    deliveryCost,
    shipments,
  } = orderDetailResponse;
  const { currencyIso, currencyName, value } = totalPrice;

  const sellers = getSellers(orderDetailResponse);

  const orderGrandTotal = getFormattedPrice(
    { currencyIso, currencyName, value },
    false,
    true,
    true
  );
  const isOrderSubstituted = isOrderSubstituatable(orderDetailResponse);
  const shipmentTypes = getShipmentTypes(shipments);

  return {
    deliveryCost,
    orderGrandTotal,
    isOrderSubstituted,
    sellers,
    orderNumber,
    totalPrice: value,
    shipmentTypes,
    totalItems,
  };
};

export const getMKPDeliveryCost = (
  marketPlaceDeliveryCost: IMarketPlaceDeliveryCost[],
  sellerEntry: ISellersEntry
) => {
  const { deliveryCost = {} as DeliveryCost } =
    marketPlaceDeliveryCost.find(
      mkpDeliveryCost =>
        mkpDeliveryCost.isFbc === sellerEntry.isFbc && mkpDeliveryCost.shopId === sellerEntry.shopId
    ) || {};

  return deliveryCost;
};

/**
 *
 * @description - Converts order detail API response's shipments to shipmnent data which needs to render order confirmatoin page
 * @param shipments - Order detail API response's shipments
 * @returns
 */
export const prepareShipments = (
  shipments: IShipment[],
  apigeeLabels?: IMapString
): IOrderConfirmationShipment[] => {
  const orderConfirmationShipments: IOrderConfirmationShipment[] = [];
  shipments.forEach((shipment: IShipment) => {
    if (shipment.shipmentType === EShipmentTypes.MARKETPLACE && shipment.sellersEntries) {
      const { marketPlaceDeliveryCost = [] } = shipment;
      shipment.sellersEntries.forEach((sellerEntry: ISellersEntry, index: number) => {
        const products = getShipmentProductsData(sellerEntry);
        const deliveryDescription = getDeliveryDescription(sellerEntry, EShipmentTypes.MARKETPLACE);
        const isExpressNow = !!sellerEntry.expressCount;
        const isFbc = sellerEntry.isFbc;
        const shipmentDeliveryFee = getMKPDeliveryCost(marketPlaceDeliveryCost, sellerEntry);
        orderConfirmationShipments.push({
          deliveryDescription,
          isExpressNow,
          ...getShipmentAndFeeLabels({
            shipmentType: EShipmentTypes.MARKETPLACE,
            sellerName: sellerEntry.shopName,
            apigeeLabels,
          }),
          products,
          shipmentTotal: sellerEntry.shipmentTotal,
          type: EShipmentTypes.MARKETPLACE,
          isFbc,
          shipmentDeliveryFee: isNonEmptyObject(shipmentDeliveryFee)
            ? shipmentDeliveryFee
            : undefined,
        });
      });
    } else {
      const { shipmentType } = shipment;
      const deliveryDescription = getDeliveryDescription(shipment, shipmentType);
      const products = getShipmentProductsData(shipment);
      // TODO_C4JOURNEY
      orderConfirmationShipments.push({
        deliveryDescription,
        ...getShipmentAndFeeLabels({ shipmentType, apigeeLabels }),
        shipmentTotal: shipment.shipmentTotal,
        products,
        type: shipmentType,
        shipmentDeliveryFee: shipment.shipmentDeliveryFee,
        shipmentServiceFee: shipment.shipmentServiceFee,
        shipmentSurCharge: shipment.shipmentSurCharge,
      });
    }
  });

  return orderConfirmationShipments;
};

/**
 * @description - Converts order detail API response to order confirmation redux format
 * @param orderDetailResponse - Order Detail API Response
 * @returns
 */
export const transformData = (
  orderDetailResponse: IConfirmationOrderDetailsResponse,
  apigeeLabels: IMapString
) => {
  const {
    convertedLoyaltyPointsValue = '',
    deliveryAddress,
    codFee = {},
    paymentInfo,
    shipments = [],
    appliedVouchers = [],
    loyaltyVouchers = [],
    user = {},
    packingCharges,
    paymentMode,
    sustainabilityConsent = null,
    totalAppliedDiscounts,
    totalBundleDiscount,
    totalFees,
    subTotal,
    totalPrice,
    isNewUser,
  } = orderDetailResponse;

  const orderMeta = prepareOrderMeta(orderDetailResponse);
  const shipmentsData = prepareShipments(shipments, apigeeLabels);
  // Passing isLocationCXEnable value true as it will always be true
  const shippingAddress = getFormattedFullAddress(deliveryAddress, true);
  const purchasedProductData = getPurchasedProductsData(orderDetailResponse, false);
  const purchasedGa4ProductData = getPurchasedProductsData(orderDetailResponse, true);

  const { phone } = deliveryAddress;
  const { uid: email = '', name } = user;
  const contactDetails = {
    email,
    phone,
    name,
  };

  return {
    appliedVouchers,
    loyaltyVouchers,
    codFee,
    contactDetails,
    convertedLoyaltyPointsValue,
    plasticBagPackingInfo: packingCharges || {
      calculated: true,
      quantity: 0,
      totalCharges: {
        value: 0,
        currencyIso: totalPrice.currencyIso,
      },
    },
    orderMeta,
    paymentInfo,
    purchasedProductData,
    purchasedGa4ProductData,
    shippingAddress,
    shipmentsData,
    sustainabilityConsent,
    totalAppliedDiscounts,
    totalBundleDiscount,
    paymentMode,
    totalFees,
    subTotal,
    totalPriceWithTax: totalPrice,
    isNewUser,
  };
};

/**
 * @description - Provides very basic order details form localstorage
 * @returns
 */
export const getBasicOrderDetailFromStorage = () => {
  const storedOrderData: any = JSON.parse(
    localStorage.getItem(ORDER_CONFIRMATION_DETAILS_KEY) || '{"userData":{},"totalPriceWithTax":{}}'
  );
  const {
    userData: { email, phone, name },
    orderNumber,
    totalPriceWithTax: { currencyIso, currencyName, value },
  } = storedOrderData;

  return {
    contactDetails: {
      email,
      phone,
      name,
    },
    orderMeta: {
      orderGrandTotal: getFormattedPrice({ currencyIso, currencyName, value }, false, true, true),
      totalPrice: value,
      orderNumber,
    },
  };
};

/**
 * @description - Returns new order confirmation api is deployed or not
 * @param state - Current Redux State
 * @returns boolean
 */
export const isNewOrderConfirmationApiDeployed = (state: IAppState): boolean => {
  const {
    appConfig: { countryConfiguration = {} },
  } = state;
  const { checkout: checkoutConfig = {} } = countryConfiguration;
  const { isNewOrderConfirmationApi = false } = checkoutConfig;

  return isNewOrderConfirmationApi;
};
