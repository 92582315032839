import { GTM_PAYMENT_METHOD_MAPPING } from 'pages/Checkout/constants/gtm.constant';
import { initStorageManager } from './customStorage.util';
import { GTMEventCategories } from '@mafc/common';
import { getJourneySelectionParams, getUserGTMProps } from './gtm.utils';
import { EShipmentTypes } from 'services/shipments/types/shipments.types';
import { getShipmentData } from 'utils/getSelectedAddress.util';
import Tracker from 'utils/Tracker';
import Transform from 'utils/TransformData';
import { COUNTRY_CODE_KEY } from 'pages/Cart/constants/cart.constants';
import { GTMEventType, GTMEvents } from 'constants/gtm.constants';

declare var dataLayer: any;

abstract class TrackerCheckout {
  static eventAction = 'checkout';
  static gtmPurchaseEventSessionName = 'checkingOut';
  static journeySelectionParams = getJourneySelectionParams();

  static gtmExpressToggleLoaded(defaultSlot: string = '') {
    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: GTMEventCategories.enhanced_ecommerce,
      event_action: TrackerCheckout.eventAction,
      event_label: 'express_toggle_loaded',
      ...(defaultSlot ? { express_time_slot: defaultSlot } : {}),
    });
  }

  static CtaClickedWithExpressLoaded(slot: string = '', expressToggled: boolean = false) {
    const partialEvents = expressToggled
      ? {
          event_label: 'placed_order_express_toggle_enabled',
          express_time_slot: slot,
        }
      : { event_label: 'placed_order_express_toggle_disabled' };

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: GTMEventCategories.enhanced_ecommerce,
      event_action: TrackerCheckout.eventAction,
      ...partialEvents,
    });
  }

  static gtmExpressToggleClicked(slot: string = '') {
    const partialEvents = slot
      ? {
          event_label: 'express_toggle_enabled',
          express_time_slot: slot,
        }
      : { event_label: 'express_toggle_disabled' };

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_category: GTMEventCategories.enhanced_ecommerce,
      event_action: TrackerCheckout.eventAction,
      ...partialEvents,
    });
  }

  static addPaymentInfo(items: any, extraObj: Record<string, string | number> = {}) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.add_payment_info,
      event_category: GTMEventCategories.enhanced_ecommerce,
      ga4: 'true',
      ...userProps,
      ...TrackerCheckout.journeySelectionParams,
      ecommerce: {
        items,
      },
      value: extraObj.cartValue,
      payment_type: extraObj.paymentType,
      screen_type: 'checkout',
      screen_name: 'checkout',
    });
  }

  static purchaseFailed(items: any, extraObj: Record<string, string | number> = {}) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.purchase_failed,
      event_action: GTMEvents.purchase_failed,
      event_category: GTMEventCategories.enhanced_ecommerce,
      ga4: 'true',
      ecommerce: {
        items,
      },
      ...userProps,
      ...TrackerCheckout.journeySelectionParams,
      value: extraObj.cartValue,
      payment_type: extraObj.paymentType,
      purchase_failed_reason: extraObj.failedReason,
      transaction_id: extraObj.orderId,
      screen_type: 'checkout',
      screen_name: 'checkout',
    });
  }

  // Need to save this flag into the session in order to trigger gtmPurchaseEvent on the B2C Order Summary page
  // See line 132 in /js/pages/checkoutpage.js file of the B2C codebase for ref
  static setPurchaseEventSession() {
    const storage = initStorageManager(() => sessionStorage);
    storage.setItem(TrackerCheckout.gtmPurchaseEventSessionName, '1');
  }

  static paymentMethodClicked(paymentMethod: string) {
    const gtmPaymentMethod = GTM_PAYMENT_METHOD_MAPPING[paymentMethod];

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: 'payment_method_clicked',
      payment_method: gtmPaymentMethod,
    });
  }

  static driverTipClicked(tip: number) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.driver_tips_clicked,
      event_category: GTMEventCategories.user_engagement,
      event_label: `${userProps.currency}_${tip}`,
      event_type: GTMEventType.interaction,
      screen_type: 'checkout',
      ga4: 'true',
      ...TrackerCheckout.journeySelectionParams,
      ...userProps,
    });
  }

  static availablePromotionsTabClicked(noCouponFound: boolean) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: noCouponFound ? GTMEvents.voucher_section : GTMEvents.all_vouchers,
      event_category: GTMEventCategories.user_engagement,
      event_label: noCouponFound ? 'no_vouchers' : 'voucher_list',
      screen_name: 'voucher_list_without_tabs',
      screen_type: 'voucher',
      ga4: 'true',
      ...userProps,
    });
  }

  static availablePromotionsEnterCode() {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.enter_manual_code_clicked,
      event_category: GTMEventCategories.user_engagement,
      event_label: 'cta_enter_code',
      screen_name: 'voucher_enter_coupon_code_manually',
      screen_type: 'voucher',
      ga4: 'true',
      ...userProps,
    });
  }

  static availablePromotionsToggleTnC({
    isExpanded,
    voucherCode,
  }: {
    isExpanded: boolean;
    voucherCode: string;
  }) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: isExpanded ? 'hide_t&c_clicked' : 'show_t&c_clicked',
      event_category: GTMEventCategories.user_engagement,
      event_label: voucherCode,
      screen_name: 'voucher_list_without_tabs',
      screen_type: 'voucher',
      ga4: 'true',
      ...userProps,
    });
  }

  static availablePromotionsVoucherApplied(voucherCode: string) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.apply_coupon_code_clicked,
      event_category: GTMEventCategories.user_engagement,
      event_label: `${voucherCode}_applied_success`,
      screen_name: 'voucher_list_without_tabs',
      coupon_code: voucherCode,
      screen_type: 'voucher',
      ga4: 'true',
      ...userProps,
    });
  }

  static voucherAppliedManually(voucherCode: string) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.apply_coupon_code_manual_clicked,
      event_category: GTMEventCategories.user_engagement,
      event_label: `${voucherCode}_applied_manual_success`,
      screen_name: 'voucher_enter_coupon_code_manually',
      coupon_code: voucherCode,
      screen_type: 'voucher',
      ga4: 'true',
      ...userProps,
    });
  }

  static selectAVoucherClicked() {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.select_voucher_clicked,
      event_category: GTMEventCategories.user_engagement,
      event_label: 'cta_select_voucher',
      screen_name: 'checkout',
      screen_type: 'checkout',
      ga4: 'true',
      ...userProps,
    });
  }

  static voucherRemovedWhenPaymentMethodChanged(voucherCode: string) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: GTMEvents.previous_coupon_removed_when_payment_method_changed,
      event_category: GTMEventCategories.user_engagement,
      event_label: `${voucherCode}_removed_success`,
      coupon_code: voucherCode,
      screen_name: 'checkout',
      screen_type: 'checkout',
      ga4: 'true',
      ...userProps,
    });
  }

  static changeCurrency() {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: 'change_currency',
      event_category: GTMEventCategories.user_engagement,
      event_label: 'cta_link_clicked',
      screen_name: 'checkout',
      screen_type: 'checkout',
      ga4: 'true',
      ...userProps,
    });
  }

  static changeCurrencyContinueClicked(currency: string) {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: 'change_currency',
      event_category: GTMEventCategories.user_engagement,
      event_label: `cta_continue_ button_clicked|${currency}`,
      screen_name: 'change_currency',
      screen_type: 'payments',
      ga4: 'true',
      ...userProps,
    });
  }

  static changeCurrencyCancelClicked() {
    const userProps = getUserGTMProps();

    dataLayer.push({
      event: GTMEvents.custom_event,
      event_action: 'change_currency',
      event_category: GTMEventCategories.user_engagement,
      event_label: 'cta_cancel_ button_clicked',
      screen_name: 'change_currency',
      screen_type: 'payments',
      ga4: 'true',
      ...userProps,
    });
  }

  static beginCheckoutGTMEvent = (
    cartData: any,
    shipmentIdentifire: string,
    isNewJourney: boolean
  ) => {
    const { shipments } = cartData;
    const storage = initStorageManager(() => sessionStorage);

    const countryCode = storage.getItem(COUNTRY_CODE_KEY) || undefined;
    const eventData = {
      shipments_cnc: getShipmentData(shipments, EShipmentTypes.CNC),
      shipments_std_food: getShipmentData(shipments, EShipmentTypes.STANDARD_FOOD),
      shipments_std_nonfood: getShipmentData(shipments, EShipmentTypes.STANDARD_NONFOOD),
      shipments_mkt: getShipmentData(shipments, EShipmentTypes.MARKETPLACE),
      shipments_express: getShipmentData(shipments, EShipmentTypes.EXPRESS),
      shipments_qcomm: getShipmentData(shipments, EShipmentTypes.QCOMM),
      shipments_qelec: getShipmentData(shipments, EShipmentTypes.QELEC),
    };
    Tracker.gtmCheckoutPageLoad(
      {
        cartId: '',
        products: Transform.productData(cartData, undefined, undefined, {
          ga4: true,
          countryCode,
        }),
        screenType: 'cart',
        screenName: 'cart',
        isNewJourney,
        stepCount: 1,
        shipmentIdentifire,
      },
      true,
      eventData,
      shipments.length
    );
  };
}

export default TrackerCheckout;
