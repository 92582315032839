import { EShipmentTypes } from 'services/shipments/types/shipments.types';

// TODO: Change to Enum type?
export default {
  addressList: 'addressList',
  newAddress: 'newAddress',
  itemStatusList: 'itemStatusList',
  loyaltyPointsThreshold: 1,
};

export const MAXIMUM_VISIBLE_ADDRESS = 2;

export enum CHECKOUT_ERRORS_TYPE {
  PAYMENT_MODE_NOT_AVAILABLE = 'PaymentModeNotAvailable',
}
export enum GATEWAY_TYPE {
  CHECKOUT = 'CHECKOUT',
  CYBER_SOURCE = 'CYBERSOURCE',
}
export const APPLY_PROMO_CODE_API_TIMEOUT = 30 * 1000;
export const TRANSACTION_ID = 'transaction-id';
export const TRANSACTION_DATA = 'transaction-data';
export const ORDER_ID = 'order-id';
export enum CurrencyCodes {
  USD = 'USD',
  LBP = 'LBP',
}

export enum NEW_RELIC_EVENT {
  PLACE_ORDER_CLICKED = 'place_order_clicked',
  PAYMENT_ARCHITECURE_API_FAILURE = 'payment_architecture_api_failure',
  PAYMENT_ARCHITECURE_API_SUCCESS = 'payment_architecture_api_success',
  CREATE_AND_UPDATE_API_SUCCESS = 'create_and_update_success',
  CREATE_AND_UPDATE_API_FAILURE = 'create_and_update_failure',
  INIT_PAYMENT_SUCCESS = 'init_payment_success',
  INIT_PAYMENT_FAILURE = 'init_payment_failure',
  ORDER_SUCCESS = 'ORDER_SUCCESS',
  TRANSACTION_STATUS_SUCCESS = 'transaction_status_success',
  TRANSACTION_STATUS_PENDING = 'transaction_status_pending',
  TRANSACTION_STATUS_FAILED = 'transaction_status_failed',
  CHECKOUT_PAGE_VIEWED = 'checkout_page_viewed',
  CHECKOUT_BUTTON_CLICKED = 'checkout_button_clicked',
  ORDER_CONFIRMATION = 'checkout_order_confirmation',
}

export const SHIPMENT_CHECKOUT_TYPE: { [key: string]: string } = {
  QCOMM: 'QCOMM',
  QELEC: 'QELEC',
  EXPRESS: 'EXPRESS',
  MKP: 'MKP',
  STANDARD_NONFOOD: 'NONFOOD',
  SLOTTED: 'SLOTTED',
  DEFAULT: 'DEFAULT',
};

export const CHECKOUT_TYPE_TO_SHIPMENT: { [key: string]: string } = {
  [SHIPMENT_CHECKOUT_TYPE.QCOMM]: EShipmentTypes.QCOMM,
  [SHIPMENT_CHECKOUT_TYPE.QELEC]: EShipmentTypes.QELEC,
  [SHIPMENT_CHECKOUT_TYPE.EXPRESS]: EShipmentTypes.EXPRESS,
  [SHIPMENT_CHECKOUT_TYPE.STANDARD_NONFOOD]: EShipmentTypes.STANDARD_NONFOOD,
  [SHIPMENT_CHECKOUT_TYPE.SLOTTED]: EShipmentTypes.STANDARD_FOOD,
};
