import { ProductUomType } from 'pages/ReturnList/returnData.type';

export enum EPostOrderDataActions {
  GET_ORDER_LIST_REQ = 'PostOrder/GET_ORDER_LIST_REQ',
  GET_ORDER_LIST = 'PostOrder/GET_ORDER_LIST',
  GET_CONSIGNMENT_CANCELLATION_AVAILABILITY = 'PostOrder/GET_CONSIGNMENT_CANCELLATION_AVAILABILITY',
  CONSIGNMENT_CANCELLATION_AVAILABILITY = 'PostOrder/CONSIGNMENT_CANCELLATION_AVAILABILITY',
  GET_CANCELLATION_REASONS_REQ = 'PostOrder/GET_CANCELLATION_REASONS_REQ',
  GET_CANCELLATION_REASONS = 'PostOrder/GET_CANCELLATION_REASONS',
  CANCEL_CONSIGNMENT_REQ = 'PostOrder/CANCEL_CONSIGNMENT_REQ',
  CANCEL_CONSIGNMENT = 'PostOrder/CANCEL_CONSIGNMENT',
  RETURN_CHECK_REQ = 'PostOrder/CHECK_RETURNS_REQ',
  RETURN_CHECK = 'PostOrder/CHECK_RETURNS',
  RETURN_REASONS_REQ = 'PostOrder/GET_RETURN_REASONS_REQ',
  RETURN_REASONS = 'PostOrder/GET_RETURN_REASONS',
  RETURN_ELIGIBLE_FLAGS_REQ = 'PostOrder/GET_RETURN_ELIGIBLE_FLAGS_REQ',
  RETURN_ELIGIBLE_FLAGS = 'PostOrder/GET_RETURN_ELIGIBLE_FLAGS',
  MULTI_RETURN_ELIGIBLE_FLAGS = 'PostOrder/GET_MULTI_RETURN_ELIGIBLE_FLAGS',
  MULTI_RETURN_ELIGIBLE_FLAGS_REQ = 'PostOrder/GET_MULTI_RETURN_ELIGIBLE_FLAGS_REQ',
  MULTI_RETURN_ELIGIBLE_FLAGS_LOADING_START = 'PostOrder/GET_MULTI_RETURN_ELIGIBLE_FLAGS_REQ_START',
  MULTI_RETURN_ELIGIBLE_FLAGS_LOADING_END = 'PostOrder/GET_MULTI_RETURN_ELIGIBLE_FLAGS_REQ_END',
  MULTI_RETURN_ELIGIBLE_FLAGS_ERROR = 'PostOrder/GET_MULTI_RETURN_ELIGIBLE_FLAGS_REQ_FAIL',
  RETURN_METHODS_REQ = 'PostOrder/GET_RETURN_METHODS_REQ',
  RETURN_METHODS = 'PostOrder/GET_RETURN_METHODS',
  REFUND_METHODS_REQ = 'PostOrder/GET_REFUND_METHODS_REQ',
  REFUND_METHODS = 'PostOrder/GET_REFUND_METHODS',
  REFUND_METHODS_LOADING_START = 'PostOrder/GET_REFUND_METHODS_REQ_START',
  REFUND_METHODS_LOADING_END = 'PostOrder/GET_REFUND_METHODS_REQ_END',
  REFUND_METHODS_ERROR = 'PostOrder/GET_REFUND_METHODS_REQ_FAIL',
  REFUND_DETAILS_REQ = 'PostOrder/GET_REFUND_DETAILS_REQ',
  REFUND_DETAILS = 'PostOrder/GET_REFUND_DETAILS',
  UPLOAD_RETURN_IMAGES_REQ = 'PostOrder/UPLOAD_RETURN_IMAGES_REQ',
  UPLOAD_RETURN_IMAGES_LOADING_START = 'PostOrder/UPLOAD_RETURN_IMAGES_REQ_START',
  UPLOAD_RETURN_IMAGES_LOADING_END = 'PostOrder/UPLOAD_RETURN_IMAGES_REQ_END',
  UPLOAD_RETURN_IMAGES_ERROR = 'PostOrder/UPLOAD_RETURN_IMAGES_REQ_FAIL',
  UPLOAD_RETURN_IMAGES_ERROR_RESET = 'PostOrder/UPLOAD_RETURN_IMAGES_REQ_ERROR_RESET',
  UPLOAD_RETURN_IMAGES = 'PostOrder/UPLOAD_RETURN_IMAGES',
  CREATE_RMA_REQ = 'PostOrder/CREATE_RMA_REQ',
  CREATE_RMA = 'PostOrder/CREATE_RMA',
  CREATE_RMA_LOADING_START = 'PostOrder/CREATE_RMA_REQ_START',
  CREATE_RMA_LOADING_END = 'PostOrder/CREATE_RMA_REQ_END',
  CREATE_RMA_ERROR = 'PostOrder/CREATE_RMA_REQ_FAIL',
  GET_RETURN_LIST_REQ = 'PostOrder/GET_RETURN_LIST_REQ',
  GET_RETURN_LIST = 'PostOrder/GET_RETURN_LIST',
  MULTI_RETURN_CHECK_REQ = 'PostOrder/MULTI_RETURN_CHECK_REQ',
  MULTI_RETURN_CHECK = 'PostOrder/MULTI_CHECK_RETURNS',
  MULTI_RETURN_STEP_SELECTION = 'PostOrder/MULTI_RETURN_STEP_SELECTION',
  MULTI_RETURN_SELECTION_DATA = 'PostOrder/MULTI_RETURN_SELECTION_DATA',
  MULTI_RETURN_UPDATE_CACHE_DATA = 'PostOrder/MULTI_RETURN_UPDATE_CACHE_DATA',
}
export enum EPlasticBagActions {
  FETCH_USER_CONSENT = 'UserData/FETCH_USER_CONSENT',
  SET_USER_CONSENT = 'UserData/SET_USER_CONSENT',
}
export interface IPaymentData {
  paymentMethod: string;
  paymentProvider: string;
  cardType: string;
  cardNumber?: string;
  plannedAmount: number;
}

export enum PAYMENT_PROVIDER_TYPE {
  CASHBACK = 'CASHBACK',
  COD = 'CashOnDelivery',
  CARD_ON_DELIVERY = 'CardOnDelivery',
  CYBERSOURCE = 'Cybersource',
  CHECKOUT = 'CHECKOUT',
  QPAY = 'QPAY',
  EASYPAISA = 'EASYPAISA',
  MPESA = 'IPAYMPESA',
  KNET = 'KNET',
  BENEFITPAY = 'BENEFITPAY',
  WALLET = 'WALLET',
  MAFPAY = 'MAFPAY',
  APPLEPAY = 'applepay',
  SAMSUNGPAY = 'samsungpay',
  MANUAL_VOUCHER = 'MANUAL_REFUND_VOUCHER',
  MASTERCARD = 'MASTERCARD',
  TABBY = 'MAFPAY_TABBY',
  VALU = 'VALU',
}
export interface IDeliveryCharges {
  type: string;
  value: number;
  name: any;
}

export interface IMiscCharge {
  type: string;
  value: number;
  wasValue?: number;
  name: Record<string, string>;
}

export interface IEarnings {
  type: string;
  amount: number;
  name: any;
  promoCode?: string;
}

export interface Deductions {
  type: string;
  amount: number;
  name: any;
  promocode?: string;
}
export interface IPostOrderDetailsResponse {
  code: string;
  consignments: any[];
  date: string;
  currency: string;
  totalPrice: number;
  subTotal: number;
  totalDeliveryFee?: number;
  payments: IPaymentData[];
  charges: IDeliveryCharges[];
  miscCharges: IMiscCharge[];
  deductions?: Deductions[];
  earnings?: IEarnings[];
  location: any;
  status: string;
  customerEmail: string;
  packingCharges?: any;
  memberPlusOrder?: boolean;
}

export interface OrderShipmentItem {
  imageUrl: string;
  productName: { [key: string]: string };
  bundleId?: string;
  unitPrice?: number;
  totalPrice?: number;
  discounts?: any[];
  productCode: string;
  qtyOrdered?: number;
  rejectedQty?: number;
  cancelledQty?: number;
  barcode?: string;
  amendedEntry?: boolean;
  isPackingItem?: boolean;
  IMEIRequired?: boolean;
  IMEISkipped?: boolean;
  IMEINumbers?: any[];
  itemStatus?: ItemStatus;
  substitutionList?: OrderShipmentItem[];
  unit?: ProductUomType;
  qtyShipped?: number;
  qtyDelivered?: number;
}

export enum OrderStatusType {
  checkedValid = 'CHECKED_VALID',
  checkedInvalid = 'CHECKED_INVALID',
  paymentCaptured = 'PAYMENT_CAPTURED',
  paymentNotAuthorized = 'PAYMENT_NOT_AUTHORIZED',
  paymentAuthorized = 'PAYMENT_AUTHORIZED',
  cancelled = 'CANCELLED',
}
export interface IPostOrderListData {
  orders: IPostOrderDetailsResponse[];
  pagination: any;
}

export interface IPostOrderList {
  orders: IPostOrderDetailsResponse[];
  currentPage: number;
  totalPages: number;
  isLoading: boolean;
}

export enum EConsignmentStatus {
  orderPlaced = 'Order Placed',
  packing = 'Packed',
  OnTheWay = 'On the way',
  delivered = 'Delivered',
  cancelled = 'Cancelled',
  readyToCollect = 'Ready to Collect',
  deliveryFailed = 'Delivery Failed',
  pendingdelivery = 'Arriving soon',
}
export enum E_CONSIGNMENT_STATUS_CODE {
  CANCELLED = 'CANCELLED',
  DISPATCHED = 'DISPATCHED',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export enum EarningsType {
  LOYALTY = 'LOYALTY',
}

export enum EShipmentType {
  QCOMM = 'QCOMM',
  QELEC = 'QELEC',
  standard = 'NF',
  express = 'FEX',
  CNC = 'FC',
  Food = 'F',
  MarketPlace = 'MKP',
}

export enum ItemStatus {
  oos = 'OOS',
  cancelled = 'CANCELLED',
  substituted = 'SUBSTITUTED',
}
export const DELIVERY_FAILED = 'Delivery Failed';
export const CASH_ON_DELIVERY = 'COD';

export enum FEE_TYPE {
  DELIVERY = 'DELIVERY',
}

export enum EFulFilmentMode {
  SELLER_SHIPPED = 'seller_shipped',
  CARREFOUR_SHIPPED = 'carrefour_shipped',
  FBC = 'fbc',
}

export enum PartialDelivery {
  oos = 'oos',
  rejection = 'rejection',
  oosRejection = 'oosRejection',
}

export interface ICancellationReasons {
  reasons: IReasonObj[];
  cod: IReasonObj[];
}

export interface IReasonObj {
  [id: string]: any;
}
