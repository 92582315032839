import { IAction, TDispatch } from 'config/redux/types/redux.types';
import { TSlotItem } from 'common/DeliverySlot/types/deliverySlot.types';
// TODO: refactor Cart types
import { DeliveryMode, Entry, Price } from 'network/response/ICart';
import { IPointOfService } from 'pages/Checkout/components/PickupStores/types/PickupStores.types';
import { MarketPlaceCodCost } from 'common/OrderSummary/redux/orderSummary.reducer';
import { IFinalPrice } from 'utils/price.util';

export enum EShipmentsActionTypes {
  SHIPMENTS_SET_STATE = 'Shipments/SET_STATE',
}

export enum EShipmentTypes {
  QCOMM = 'QCOMM',
  QELEC = 'QELEC',
  EXPRESS = 'EXPRESS',
  CNC = 'CNC',
  MARKETPLACE = 'MARKETPLACE',
  STANDARD_FOOD = 'STANDARD_FOOD',
  STANDARD_NONFOOD = 'STANDARD_NONFOOD',
}

export interface ISellersEntry {
  entries: Entry[];
  maxDays: number;
  minDays: number;
  shopId: string;
  shopName: string;
  expressCount?: number;
  hideExpressToggle?: boolean;
  expressDeliveryMessage?: string;
  expressDeliveryTitle?: string;
  deliveryPromise?: string;
  isFbc: boolean;
  shipmentTotal: Price;
}

export interface IMarketPlaceDeliveryCost {
  isFbc: boolean;
  shopId: string;
  shopName: string;
  deliveryCost: IFinalPrice;
}

export interface IShipment {
  description?: any;
  hideDeliveryCost?: boolean;
  deliveryMode: DeliveryMode;
  deliverySlot?: TSlotItem;
  entries: Entry[];
  marketPlaceDeliveryCost?: any[];
  sellersEntries: ISellersEntry[];
  shipmentType: EShipmentTypes;
  standardFee?: Price;
  marketplaceCodCost?: MarketPlaceCodCost[];
  deliveryPromise?: string;
  shipmentTotal: Price;
  IFinalPrice?: IFinalPrice;
  shipmentDeliveryFee?: IFinalPrice;
  shipmentServiceFee?: IFinalPrice;
  shipmentSurCharge?: IFinalPrice;
}

export interface ICncShipment {
  deliverySlot: TSlotItem;
  entries: Entry[];
  shipmentType: EShipmentTypes;
  store: IPointOfService;
  shipmentTotal: Price;
}

export interface ITime {
  formattedHour?: string;
  hour: string;
  minute: string;
}

export interface ISlots {
  closingTime: ITime;
  closingTime2: ITime;
  openingTime: ITime;
  openingTime2: ITime;
  closed: boolean;
  weekDay: string;
}

export type TShipments = IShipment[] | null;

export interface IShipmentsState {
  shipments: TShipments;
}

export type TShipmentsAction = IAction<TShipments, EShipmentsActionTypes>;
export type TShipmentsDispatch = TDispatch<TShipmentsAction>;

interface ICurrentShipmentNumber {
  currentShipmentCount: number;
}

export interface IShipmentsHook {
  shipments: TShipments;
  clickAndCollect: ICncShipment & ICurrentShipmentNumber;
  qComm: IShipment & ICurrentShipmentNumber;
  qelec: IShipment & ICurrentShipmentNumber;
  express: IShipment & ICurrentShipmentNumber;
  marketplace: IShipment & ICurrentShipmentNumber;
  standardFood: IShipment & ICurrentShipmentNumber;
  standardNonFood: IShipment & ICurrentShipmentNumber;
  hasClickAndCollect: boolean;
  hasQComm: boolean;
  hasQelec: boolean;
  hasExpress: boolean;
  hasMarketplace: boolean;
  hasStandardFood: boolean;
  hasStandardNonFood: boolean;
  hasShipmentFood: boolean;
  hasShipmentNonFood: boolean;
  isFoodCart: boolean;
  isNonFoodCart: boolean;
  hasShipments: boolean;
  isLoadedOnce: boolean;
  allShipments: IShipment[];
  carrefourShipmentCount: number;
  marketPlaceShipmentCount: number;
  pharmacyItemCount: number;
  carrefourItemsCount: number;
  totalShipmentCount: number;
}
