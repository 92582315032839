export const DEFAULT = 'DEFAULT';

const EXPRESS = 'EXPRESS';

const QCOMM = 'QCOMM';

const QELEC = 'QELEC';

const NONFOOD = 'NONFOOD';

const SLOTTED = 'SLOTTED';

export const SHIPMENT_IDENTIFIRES = {
  DEFAULT,
  QCOMM,
  QELEC,
  EXPRESS,
  NONFOOD,
  SLOTTED,
};
